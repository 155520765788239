import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import useProjectStatuses from "../../hooks/projectStatuses";

import "./Timeline.scss";

export default function Timeline({ active: activeSlug, items, ...restProps }) {
  let allStatuses = items ? items : useProjectStatuses();
  const activeStatus = allStatuses.find((status) => status.slug === activeSlug);
  const activeStatusIndex = allStatuses.findIndex(
    (status) => status.slug === activeSlug,
  );
  return (
    <div
      className={cx(
        "c-timeline o-margin-bottom-large",
        activeStatus &&
          activeStatus.options.color &&
          `c-timeline--${activeStatus.options.color}`,
      )}
      {...restProps}
    >
      {allStatuses && allStatuses.length && (
        <ul className="c-timeline__list">
          {allStatuses.map((status, index) => {
            let isActiveStatusIndex = activeStatusIndex === index;
            return (
              <li
                key={index}
                className={cx(
                  "c-timeline__item",
                  isActiveStatusIndex && "c-timeline__item--active",
                  activeStatusIndex > index && "c-timeline__item--past",
                )}
              >
                <span className="c-timeline__flicker" />
                {status.name}
                {isActiveStatusIndex && status.description && (
                  <>
                    <i className="c-timeline__arrow" aria-hidden="true" />
                    <span className="c-timeline__description">
                      <strong>{status.name}</strong>
                      {status.description}
                    </span>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
